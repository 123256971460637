

export default function TermsCondition() {
  return (
    <>
       <div className="container mx-auto px-4 py-8 text-white">
      <h1 className="text-3xl font-semibold mb-6">Terms and Conditions</h1>
      <p>Welcome to droppGroup! (dropp TV Holdings, Inc. DBA droppGroup)</p>
      <p>These terms and conditions outline the rules and regulations for the use of dropp TV Holdings, Inc.&apos;s Website, located at www.droppgroup.xyz, www.dropplink.ai, www.droppthinklab.xyz (individually and collectively, the “Website”).</p>
      
      <h2 className="text-xl font-semibold mt-8 mb-4">By accessing the Website</h2>
      <p>We assume you accept these terms and conditions. Do not continue to use the Website if you do not agree to take all of the terms and conditions stated on this page.</p>
      
      <h2 className="text-xl font-semibold mt-8 mb-4">Cookies</h2>
      <p>The Website uses cookies to help personalize your online experience. By accessing the Website, you agreed to use the required cookies.</p>
      
      <h2 className="text-xl font-semibold mt-8 mb-4">License</h2>
      <p>Unless otherwise stated, dropp TV Holdings, Inc. and/or its licensors own the intellectual property rights for all material on the Website. All intellectual property rights are reserved. You may access this from the Website for your own personal use subjected to restrictions set in these terms and conditions.</p>
      <p>You must not:</p>
      <ul className="list-disc list-inside">
        <li>Republish material from the Website</li>
        <li>Sell, rent or sub-license material from the Website</li>
        <li>Reproduce, duplicate or copy material from the Website</li>
        <li>Redistribute content from the Website</li>
      </ul>
      
      <h2 className="text-xl font-semibold mt-8 mb-4">This Agreement</h2>
      <p>This Agreement shall begin on the date hereof.</p>
      
      <h2 className="text-xl font-semibold mt-8 mb-4">Content Liability</h2>
      <p>We shall not be responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
    </div>
    </>
  );
}

