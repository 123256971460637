const Index = () => {
  return (
    <div className="font-montserrat text-white">
      <div className="flex flex-col justify-center items-center h-[84vh]">
        <div className="flex justify-center">
          <img src="/assets/droppThink.png"></img>
        </div>
        <a
          href="mailto:careers@droppthinklab.xyz"
          className=" rounded-[30px] text-[20px] bg-[#323132] py-3 px-10"
        >
          Careers
        </a>
      </div>

      <div className=" bottom-3 w-full self-end justify-self-end">
        <p className=" flex justify-center items-center text-center gap-2">
          <a
            href="/termsandconditions"
            target="_blank"
          >
            Terms & Conditions
          </a>

          <a
            href="privacypolicy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default Index;
