import logo from "./logo.svg";
import "./App.css";

import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
import { useRef,
  useEffect } from "react";
import Index from "./pages";
import PrivacyPolicy from "./pages/privacy";
import TermsCondition from "./pages/terms";

function App() {
  function RouterComponent() {

    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    return (
      <div>
        <video
          autoPlay
          ref={videoRef}
          loop
          muted
          playsInline
          style={{
            position: "fixed",
            width: "101%",
            left: "50%",
            top: "50%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "-1",
            height: "101%",
          }}
        >
          <source src="/assets/background.mp4" type="video/mp4" />
        </video>
        
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
          <Route path="/termsandconditions" element={<TermsCondition/>} />
        </Routes>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
